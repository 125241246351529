
























































































import { Component, Vue } from 'vue-property-decorator';
import EmergencyFundDefinePlanViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/emergency-fund/emergency-fund-define-plan-view-model';

@Component({
  name: 'EmergencyFundDefinePlan',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EmergencyFundDefinePlan extends Vue {
  view_model = Vue.observable(
    new EmergencyFundDefinePlanViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
