import TYPES from '@/types';
import Vue from 'vue';

import { maxValueRule, minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// application
import CalculateCustomGoalMaximumInitialAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-initial-amount-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';

// Domain
import {
  CustomGoalMaximumInitialAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-initial-amount-dto';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  InvestorProfileStateManager,
} from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EmergencyFundDefinePlanViewModel {
  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_INITIAL_AMOUNT_QUERY)
  private readonly calculate_maximum_initial_amount_query!:
    CalculateCustomGoalMaximumInitialAmountQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  readonly investor_profile_state_manager!: InvestorProfileStateManager;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.emergency-fund.define-plan';

  readonly view: Vue;

  timer?: NodeJS.Timer;

  max_months_to_reach_goal_range = 6;

  readonly min_months_to_reach_goal_range = 3;

  readonly increase_percent = 0.05;

  is_valid_form = false;

  slider_limit = 0;

  calculated_months = 0;

  maximum_initial_amount = 0;

  inputs = {
    desired_amount: '0.0',
    initial_amount: '0.0',
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  investor_profile = this.investor_profile_state_manager.state;

  constructor(view: any) {
    this.view = view;
    this.inputs.desired_amount = (this.investor_profile
      .investor_profile.net_monthly_income * this.min_months_to_reach_goal_range).toString();
  }

  get is_continue_btn_disabled() {
    return this.investor_goal_state.is_loading || !this.is_valid_form;
  }

  inputs_rules = {
    desired_amount: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    initial_balance: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$0.00 MXN', 0),
      (value: string) => maxValueRule(
        value.replace(/[^0-9.-]/g, ''),
        `$${this.maximum_initial_amount} MXN`,
        this.maximum_initial_amount,
      ),
    ],
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  calculateSliderLimit = () => {
    this.slider_limit = (this.investor_profile.investor_profile.net_monthly_income
      * this.max_months_to_reach_goal_range);
  }

  setSliderLimit = () => {
    const months = Math.round((parseCurrencyToNumber(
      this.inputs.desired_amount,
    ) / this.investor_profile.investor_profile.net_monthly_income) * 100) / 100;

    if (parseCurrencyToNumber(this.inputs.desired_amount) > this.slider_limit) {
      this.max_months_to_reach_goal_range = Math.ceil(months);
    } else {
      this.max_months_to_reach_goal_range = 6;
    }

    this.investor_goal_state.emergency_fund.months_of_monthly_incomes = Math.floor(months);
    this.calculated_months = months;
    this.investor_goal_state.is_loading = false;
  }

  calculateNewEmergencyFund = () => {
    this.inputs.desired_amount = (this.investor_profile
      .investor_profile.net_monthly_income * this.investor_goal_state.emergency_fund
      .months_of_monthly_incomes).toString();
    this.calculated_months = this.investor_goal_state.emergency_fund.months_of_monthly_incomes;
  }

  delay = () => {
    this.investor_goal_state.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.calculateMinimumAccumulatedAmount();
      this.setSliderLimit();
    }, 2000);
  }

  loadState = () => {
    if (this.investor_goal_state.emergency_fund.months_of_monthly_incomes) {
      this.inputs.desired_amount = this.investor_goal_state.investor_goal.desired_amount.toString();
      this.inputs.initial_amount = this.investor_goal_state.investor_goal.initial_amount.toString();
      this.calculated_months = this.investor_goal_state.emergency_fund.months_of_monthly_incomes;
    } else {
      this.calculateNewEmergencyFund();
    }
  }

  calculateMinimumAccumulatedAmount = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      const payload: CustomGoalMaximumInitialAmountCalculationDto = {
        monthly_required_amount: 0,
        fixed_time_adjusted: 1,
        desired_amount: parseCurrencyToNumber(this.inputs.desired_amount),
        interest_rate: this.investor_goal_state.associated_product_interest_rate / 100,
      };
      const calculation = await this
        .calculate_maximum_initial_amount_query.internalExecute(payload);
      this.maximum_initial_amount = Math.trunc(
        parseFloat(calculation.maximum_initial_amount) * 100,
      ) / 100;
      if (this.view.$refs.form) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  updateInvestorProfile = async () => {
    try {
      const investor_profile_dto: InvestorProfileDto = {
        id: this.investor_goal_state.investor_goal.investor_profile_id,
        emergency_fund_investor_goal: {
          months_of_monthly_incomes: this.investor_goal_state.emergency_fund
            .months_of_monthly_incomes,
          initial_amount: this.investor_goal_state.investor_goal.initial_amount,
          desired_amount: this.investor_goal_state.investor_goal.desired_amount,
          monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
          fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted,
          accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
          last_step: 'EmergencyFundDefinePlan',
        },
      };
      await this.update_investor_profile_command.execute(investor_profile_dto);
      return true;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  };

  setState = () => {
    this.investor_goal_state.investor_goal.desired_amount = parseCurrencyToNumber(
      this.inputs.desired_amount,
    );
    this.investor_goal_state.investor_goal.initial_amount = parseCurrencyToNumber(
      this.inputs.initial_amount,
    );
    return this.updateInvestorProfile();
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    await this.setState();
    this.view.$emit('nextStep');
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    this.calculateSliderLimit();
    this.loadState();
    await this.calculateMinimumAccumulatedAmount();
    this.investor_goal_state.is_loading = false;
  }
}
